import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { ReleasesData, useReleases } from "../hooks/useReleases";
import AlternativeDownloads from "./AlternativeDownloads";
import ShowcasedDownload from "./ShowcasedDownload";

const Releases: React.FC = () => {
  const releases = useReleases();

  return (
    <>
      {releases.state === "loading" ? (
        <CircularProgress />
      ) : releases.state === "error" ? (
        <NoReleases />
      ) : releases.data.hostLatestRelease === undefined &&
        Object.keys(releases.data.otherLatestReleases).length === 0 ? (
        <NoReleases />
      ) : (
        <ShowReleases releases={releases.data} />
      )}
    </>
  );
};

type Props = { releases: ReleasesData };

const ShowReleases: React.FC<Props> = (props) => {
  const { releases } = props;

  return (
    <>
      <ShowcasedDownload release={releases.hostLatestRelease} />
      <AlternativeDownloads releases={releases.otherLatestReleases} />
    </>
  );
};

const NoReleases: React.FC = () => {
  return (
    <>
      <ShowcasedDownload release={undefined} />
      <Box component="p">No releases found.</Box>
    </>
  );
};

export default Releases;
