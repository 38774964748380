import { mainSvgGradientId } from "../theme";
import React from "react";

export const SVGGradientProvider: React.FC = ({ children }) => {
  return (
    <>
      <svg style={{ height: 0, width: 0, display: "block" }}>
        <linearGradient id={mainSvgGradientId} x1="0" y1="1" x2="1" y2="0">
          <stop offset="0%" stopColor="#C62A1F" />
          <stop offset="100%" stopColor="#F8C16C" />
        </linearGradient>
      </svg>
      {children}
    </>
  );
};
