import { useEffect, useState } from "react";
import {
  groupReleasesByPlatform,
  parseReleases,
  Release,
  sortReleases,
  splitReleases,
} from "../utils/releases";
import config from "../config";
import { fetchMeta } from "../utils/meta";
import { detectHost } from "../utils/detectHost";

export type ReleasesData = {
  hostLatestRelease?: Release;
  otherLatestReleases: Record<Release["platform"], Release[]>;
};

export type UseReleasesState =
  | { state: "loading" }
  | { state: "error"; error: string }
  | {
      state: "ready";
      data: ReleasesData;
    };

export const useReleases = (): UseReleasesState => {
  const [status, setStatus] = useState<UseReleasesState>({ state: "loading" });

  useEffect(() => {
    const loadMeta = async () => {
      const { metaUrl } = await config;
      const meta = await fetchMeta(metaUrl);
      return { metaUrl, meta };
    };

    const fn = async () => {
      const [loadMetaResult, detectHostResult] = await Promise.allSettled([
        loadMeta(),
        detectHost(),
      ]);

      if (loadMetaResult.status === "rejected") {
        setStatus({ state: "error", error: String(loadMetaResult.reason) });
        return;
      }
      const { meta, metaUrl } = loadMetaResult.value;

      if (detectHostResult.status === "rejected") {
        setStatus({ state: "error", error: String(detectHostResult.reason) });
        return;
      }
      const hostInfo = detectHostResult.value;

      const releases = parseReleases(meta, metaUrl);

      const { hostLatestRelease, otherLatestReleases } = splitReleases(
        sortReleases(releases),
        hostInfo
      );

      const grouped = groupReleasesByPlatform(otherLatestReleases);

      setStatus({
        state: "ready",
        data: {
          hostLatestRelease,
          otherLatestReleases: grouped,
        },
      });
    };
    void fn();
  }, []);

  return status;
};
