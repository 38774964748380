import { Release } from "./releases";

export type HostInfo = Pick<Release, "platform" | "arch">;

// For when the host is detected but it is explicitly unsupported.
export const UnsupportedHost = Symbol("detectHostUnsupported");

export const detectHost = async (): Promise<
  HostInfo | typeof UnsupportedHost
> => {
  const userAgentDataHost = await detectHostUserAgentData();
  if (userAgentDataHost) {
    return userAgentDataHost;
  }

  const userAgentHost = detectHostUserAgent();
  if (userAgentHost) {
    return userAgentHost;
  }

  // fallback: most common
  return { platform: "win32", arch: "x64" };
};

export const detectHostUserAgentData = async (): Promise<
  HostInfo | null | typeof UnsupportedHost
> => {
  const { userAgentData } = navigator;
  if (!userAgentData) {
    // User agent data unsupported in this browser.
    return null;
  }

  const { architecture, bitness, platform } =
    await userAgentData.getHighEntropyValues(["architecture", "bitness"]);

  if (!architecture) {
    return null;
  }

  let ourPlatform: null | Release["platform"] = null;
  let ourArch: null | Release["arch"] = null;

  if (platform === "macOS") {
    ourPlatform = "darwin";
  } else if (platform === "Windows") {
    ourPlatform = "win32";
  } else if (platform === "Linux") {
    ourPlatform = "linux";
  }

  if (architecture === "arm" && bitness === "64") {
    ourArch = "arm64";
  } else if (architecture === "x86" && bitness == "64") {
    ourArch = "x64";
  }

  if (ourPlatform !== null && ourArch !== null) {
    return { platform: ourPlatform, arch: ourArch };
  }

  return null;
};

export const detectHostUserAgent = (): HostInfo | null => {
  const userAgent = navigator.userAgent;
  if (userAgent.includes("Mac OS")) {
    if (userAgent.includes("Intel")) {
      return { platform: "darwin", arch: "x64" };
    } else {
      return { platform: "darwin", arch: "arm64" };
    }
  } else if (userAgent.includes("Windows")) {
    return { platform: "win32", arch: "x64" };
  } else if (userAgent.includes("Linux")) {
    return { platform: "linux", arch: "x64" };
  }

  return null;
};
