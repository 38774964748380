import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import formatRelease from "../utils/formatRelease";
import { Release } from "../utils/releases";
import { OSIcon } from "./OSIcon";

type Props = { release: Release | undefined };

const ShowcasedDownload: React.FC<Props> = (props) => {
  const { release } = props;
  return release ? <Download release={release} /> : <DisabledDownload />;
};

type DownloadProps = {
  release: Release;
};

const Download: React.FC<DownloadProps> = (props) => {
  const { release } = props;
  const { text, subtext } = formatRelease(release);

  return (
    <Stack spacing={1} direction="column" alignItems="center">
      <Button variant="contained" href={release.url} target="_blank">
        <Stack spacing={1} direction="row" alignItems="center">
          <OSIcon os={release.os} />
          <Box ml={1} />
          Download now
        </Stack>
      </Button>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>{text}</Box>
        <Box>
          <Typography variant="body2">{subtext}</Typography>
        </Box>
      </Box>
    </Stack>
  );
};

const DisabledDownload: React.FC = () => {
  return (
    <Box alignSelf="center">
      <Button variant="contained" href="" target="_blank" disabled>
        <Stack spacing={1} direction="row" alignItems="center">
          Download now
        </Stack>
      </Button>
    </Box>
  );
};

export default ShowcasedDownload;
