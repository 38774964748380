import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ReleasesData } from "../hooks/useReleases";
import formatRelease from "../utils/formatRelease";
import { Release } from "../utils/releases";
import { OSIcon } from "./OSIcon";

type Props = {
  releases: ReleasesData["otherLatestReleases"];
};

const AlternativeDownloads: React.FC<Props> = React.memo((props) => {
  const { releases } = props;

  return (
    <Box width={"100%"}>
      <Typography variant="h6" gutterBottom>
        Also available on
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        sx={{
          "> *": {
            flexBasis: "100%",
          },
        }}
      >
        {Object.entries(releases).map(([platform, releases]) => (
          <Stack
            direction="column"
            spacing={1}
            key={platform}
            sx={{
              alignItems: "center",
            }}
          >
            {releases.map((release) => (
              <Download key={release.name} release={release} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
});

type DownloadProps = {
  release: Release;
};

const Download: React.FC<DownloadProps> = React.memo((props) => {
  const { release } = props;
  const { text, subtext } = formatRelease(release);

  return (
    <Box>
      <Button href={release.url} target="_blank">
        <Box display="flex" alignItems="center">
          <OSIcon os={release.os} />
          <Box ml={1} />
          <Box display="flex" flexDirection="column">
            <Box>{text}</Box>
            <Box>
              <Typography variant="body2">{subtext}</Typography>
            </Box>
          </Box>
        </Box>
      </Button>
    </Box>
  );
});

export default AlternativeDownloads;
