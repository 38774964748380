import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import useConfig from "./hooks/useConfig";
import { aptInstructions, yumInstructions } from "./instructions";

export const Repositories: React.FC = () => {
  const cfg = useConfig();
  if (cfg === null) {
    return <></>;
  }
  return (
    <>
      <Repository
        title="Using APT"
        instructions={aptInstructions(
          cfg.aptRepoUrl,
          cfg.aptRepoSourceName,
          cfg.aptPackageName
        )}
      />
      <Repository
        title="Using YUM"
        instructions={yumInstructions(
          cfg.yumRepoUrl,
          cfg.yumRepoSourceName,
          cfg.yumPackageName
        )}
      />
    </>
  );
};

const Repository: React.FC<{ title: string; instructions: string[] }> = ({
  title,
  instructions,
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box width="100%" overflow="overlay">
          <code>
            <pre>{instructions.join("\n")}</pre>
          </code>
        </Box>
      </CardContent>
    </Card>
  );
};
