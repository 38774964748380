import { HostInfo, UnsupportedHost } from "./detectHost";
import { groupBy } from "./groupBy";
import { Entry, Meta } from "./meta";

export type OS = "linux" | "windows" | "macos";

export type Release = Entry & {
  resolvedUrl: string;
  os: OS;
};

const platformToOs = (platform: string): OS => {
  if (platform.includes("linux")) return "linux";
  if (platform.includes("mac") || platform.includes("darwin")) return "macos";
  return "windows";
};

export const parseReleases = (meta: Meta, baseUrl: string): Release[] =>
  meta.entries.map((entry) => {
    const resolvedUrl = new URL(entry.url, baseUrl).toString();
    const os = platformToOs(entry.platform);
    return {
      ...entry,
      url: resolvedUrl,
      os,
      resolvedUrl,
    };
  });

export type SplitReleases = {
  hostLatestRelease?: Release;
  otherLatestReleases: Release[];
};

export const splitReleases = (
  releases: Release[],
  host: HostInfo | typeof UnsupportedHost
): SplitReleases => {
  if (host === UnsupportedHost) {
    return {
      hostLatestRelease: undefined,
      otherLatestReleases: releases,
    };
  }

  const hostLatestRelease = releases.find(
    (x) => x.arch === host.arch && x.platform === host.platform
  );
  return {
    hostLatestRelease,
    otherLatestReleases: releases.filter((x) => x !== hostLatestRelease),
  };
};

export const sortReleases = (releases: Release[]): Release[] =>
  [...releases].sort((a, b) => a.platform.localeCompare(b.platform));

export const groupReleasesByPlatform = (
  releases: Release[]
): Record<Release["platform"], Release[]> => {
  return groupBy<Release, Release["platform"]>(
    releases,
    (release) => release.platform
  );
};
