import { Release } from "./releases";

export type ReleaseData = {
  text: string;
  subtext?: string;
};

const formatRelease = (r: Release): ReleaseData => {
  switch (r.os) {
    case "linux":
      return formatReleaseLinux(r);
    case "macos":
      return formatReleaseMacos(r);
    case "windows":
      return formatReleaseWindows(r);
    default:
      return formatReleaseGeneric(r);
  }
};

const getExt = (filename: string) =>
  filename.slice(filename.lastIndexOf(".") + 1);

const formatReleaseGeneric = (r: Release): ReleaseData => {
  const ext = getExt(r.name);
  return { text: `${r.platform} ${r.arch} (${ext})` };
};

const formatReleaseLinux = (r: Release): ReleaseData => {
  const ext = getExt(r.name);

  const platformName = r.arch == "x64" ? "Linux amd64" : `Linux ${r.arch}`;

  const distribution =
    ext === "deb"
      ? "Debian package"
      : ext === "rpm"
      ? "RPM package"
      : ext === "zip"
      ? "ZIP-archive"
      : `(${ext})`;

  return { text: platformName, subtext: distribution };
};

const formatReleaseMacos = (r: Release): ReleaseData => {
  const ext = getExt(r.name);

  // For macOS, we convert architectures to the more user-friendly names.
  const platformName =
    r.arch === "arm64"
      ? "macOS on Apple Silicon"
      : r.arch === "x64"
      ? "macOS on Intel"
      : `macOS on ${r.arch}`;

  const distribution = ext === "zip" ? "ZIP-archive" : ext;

  return { text: platformName, subtext: distribution };
};

const formatReleaseWindows = (r: Release): ReleaseData => {
  const ext = getExt(r.name);

  // For Windows, x64 is default, so we just omit the arch.
  const platformName = r.arch === "x64" ? "Windows" : `Windows ${r.arch}`;

  // The .exe installer is just the default option for windows.
  const distribution =
    ext === "exe" ? undefined : ext === "zip" ? "ZIP-archive" : ext;

  return { text: platformName, subtext: distribution };
};

export default formatRelease;
