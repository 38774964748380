export const aptInstructions = (
  repoUrl: string,
  repoName: string,
  packageName: string
): string[] => {
  return [
    `sudo apt-get install apt-transport-https`,
    `echo "deb [trusted=yes] ${repoUrl} ./" | sudo tee /etc/apt/sources.list.d/${repoName}.list`,
    "sudo apt-get update",
    `sudo apt-get install ${packageName}`,
  ];
};

export const yumInstructions = (
  url: string,
  repoName: string,
  packageName: string
): string[] => {
  return [
    `cat <<EOF | sudo tee /etc/yum.repo.d/${repoName}.repo`,
    `[${repoName}]`,
    `name=${repoName}`,
    `baseurl=${url}`,
    `enabled=1`,
    `gpgcheck=0`,
    `EOF`,
    `sudo yum install ${packageName}`,
  ];
};
