export type Entry = {
  name: string;
  url: string;
  platform: string;
  arch: string;
};

export type Meta = {
  version: string;
  entries: Entry[];
};

export const fetchMeta = async (url: string): Promise<Meta> => {
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
    },
  });
  const resBody: unknown = await res.json();
  if (isMeta(resBody)) {
    return resBody;
  }
  throw new Error("invalid meta");
};

const isMeta = (shape: unknown): shape is Meta => {
  return shape !== null && typeof shape === "object" && "entries" in shape;
};
