export const groupBy = <T, U extends string>(
  arr: T[],
  fn: (item: T) => U
): Record<U, T[]> => {
  return arr.reduce((acc, item) => {
    const key = fn(item);
    return {
      ...acc,
      [key]: [...(acc[key] ?? []), item],
    };
  }, {} as Record<U, T[]>);
};
