import React from "react";
import { OS } from "../utils/releases";

export const OSIcon: React.FC<{ os: OS }> = (props) => (
  <img
    src={`/assets/social-${props.os}-white.svg`}
    style={{ width: 30, height: 30 }}
    alt={"os " + props.os}
  />
);
