import { createTheme, Theme } from "@mui/material/styles";
import { CSSInterpolation } from "@mui/system";
declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const mainSvgGradientId = "main-svg-gradient";
export const mainGradient = "linear-gradient(45deg, #C62A1F 0%, #F8C16C 100%)";
export const textGradientStyle = {
  background: `-webkit-${mainGradient}`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};
export const gradientedBackgroundButton: CSSInterpolation = {
  background: mainGradient,
  color: "#fff",
  "&:hover": {
    boxShadow: "inset 10px 10px 40px #FFFFFF33",
  },
  "&:active": {
    boxShadow: "inset 14px 14px 30px 9px #30272733",
  },
  "&:disabled": {
    background: "#303030",
    color: "#fff",
  },
};
const baseTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: "FuturaPT",
    h1: {
      fontSize: "27px",
      lineHeight: "35px",
      textTransform: "uppercase",
      letterSpacing: "0.5rem",
    },
    h2: {
      fontSize: "0.95rem",
      textTransform: "uppercase",
      fontWeight: 400,
      letterSpacing: "0.1rem",
      color: "#fff",
    },
    body1: {
      fontSize: "0.90rem",
      fontWeight: 300,
      lineHeight: 1,
      color: "#fff",
    },
    body2: {
      fontSize: "0.85rem",
      color: "#929292",
    },
    overline: {
      letterSpacing: "0.08em",
      fontSize: "13px",
      color: "#ffffff88",
    },
    button: {
      letterSpacing: "0.15em",
      fontSize: "13px",
    },
  },
  shape: {
    borderRadius: 0,
  },
});

const theme = createTheme(
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "*::-webkit-scrollbar": {
            width: 15,
            height: 15,
          },
          "*::-webkit-scrollbar-track": {
            width: 15,
            height: 15,
            backgroundColor: "#1A1A1A",
          },
          "*::-webkit-scrollbar-thumb": {
            width: 15,
            height: 15,
            backgroundColor: "#242424",
          },
          "*::-webkit-scrollbar-corner": {
            backgroundColor: "#1A1A1A",
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            background: mainGradient,
          },
          colorPrimary: {
            backgroundColor: "#363636",
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            stroke: `url(#${mainSvgGradientId})`,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "#202020",
            backgroundImage: "none",
            padding: "35px 50px",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "0",
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: "0",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            ...gradientedBackgroundButton,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            letterSpacing: "0.1em",
            color: "#8B8B8B",
            fontWeight: 400,
            "&.Mui-selected": textGradientStyle,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          primary: {
            borderRadius: 0,
            ...gradientedBackgroundButton,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            backgroundColor: "#101010",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&:before": {
              content: "none",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              borderColor: "#ffa08b",
              backgroundColor: "rgba(255, 255, 255, 0.10)",
            },
          },
          containedPrimary: {
            ...gradientedBackgroundButton,
            "&:hover": {
              backgroundColor: "#C62A1F88",
              boxShadow: "inset 0px 0px 20px 20px #C62A1F88",
            },
          },
          containedSecondary: {
            color: "#fff8",
            textTransform: "none",
            backgroundColor: "#1A1A1A",
            boxShadow: "none",
            "&:hover": {
              background: "#262626",
              color: "#fff",
              boxShadow: "none",
            },
            "&:disabled": {
              background: "#303030",
            },
          },
          outlinedPrimary: {
            color: "#fff",
            borderColor: "#979797",
            borderWidth: 1,
            "&:disabled": {
              borderColor: "#575757",
            },
          },
          textPrimary: {
            textTransform: "none",
            color: "#fff",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#151515",
            "&.Mui-selected": gradientedBackgroundButton,
            "&:hover": {
              backgroundColor: "#262626",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": gradientedBackgroundButton,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: "#202020",
            backgroundImage: "none",
          },
        },
      },
    },
  },
  baseTheme
);
export default theme;
