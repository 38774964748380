import { Card, Container, CssBaseline, Stack, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/system";
import React from "react";
import Releases from "./components/Releases";
import { SVGGradientProvider } from "./components/SVGGradientProvider";
import { Guides } from "./Guides";
import { Repositories } from "./Repositories";
import theme from "./theme";

const App: React.FC = () => {
  return (
    <SVGGradientProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <img
              src="/assets/white-eyes-face.png"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                objectFit: "contain",
                zIndex: -1,
              }}
            ></img>
            <Container component="div" maxWidth="md">
              <Stack spacing={4} m={4}>
                <Card>
                  <Stack spacing={3} alignItems="center">
                    <img
                      src="/assets/humanode-logo.png"
                      alt="Humanode"
                      width={240}
                      height={240}
                    />
                    <Typography variant="h6">
                      Get the Humanode Launcher and run your node!
                    </Typography>
                    <Releases />
                  </Stack>
                </Card>
                <Guides />
                <Repositories />
              </Stack>
            </Container>
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </SVGGradientProvider>
  );
};

export default App;
