import { Card, Stack, Typography, Button } from "@mui/material";
import { BookOutlined } from "@mui/icons-material";

export const HelpLinks = [
  {
    link: "https://link.humanode.io/guide/launcher",
    name: "Guide for setting up a node with the launcher",
    icon: <BookOutlined />,
  },
  {
    link: "https://link.humanode.io/docs",
    name: "Documentation",
    icon: <BookOutlined />,
  },
];
export const Guides: React.FC = () => {
  return (
    <Card>
      <Stack spacing={3}>
        <Typography variant="h6" gutterBottom>
          Useful links
        </Typography>
        <Stack spacing={1}>
          {HelpLinks.map((link) => (
            <Button
              key={link.name}
              href={link.link}
              target="_blank"
              variant="text"
              startIcon={link.icon}
              sx={{ justifyContent: "flex-start" }}
            >
              {link.name}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};
