import { useEffect, useState } from "react";
import config, { Config } from "../config";

export const useConfig = (): Config | null => {
  const [cache, setCache] = useState<Config | null>(null);

  useEffect(() => {
    void config.then((val) => {
      setCache(val);
    });
  }, []);

  return cache;
};

export default useConfig;
